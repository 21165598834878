import { Placement } from "@popperjs/core";
import React, { CSSProperties, useCallback, useMemo, useState } from "react";
import { usePopper } from "react-popper";
import { Portal } from "shared/ui/menu/lib/portal";
import styles from "./tooltip.module.scss";

export interface ITooltip {
  label: React.ReactNode;
  children: React.ReactElement;

  placement?: Placement;
  arrow?: boolean;

  visible?: boolean;

  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const Tooltip = (props: ITooltip) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const modifiers = useMemo(() => {
    if (props.arrow) {
      return [
        { name: "arrow", options: { element: arrowElement } },
        { name: "offset", options: { offset: [0, 11] } },
      ];
    }

    return [{ name: "offset", options: { offset: [0, 4] } }];
  }, [arrowElement, props.arrow]);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers,
    placement: props.placement,
  });

  const [visible, setVisible] = useState(false);

  const onMouseEnter: React.MouseEventHandler = useCallback(() => setVisible(true), [setVisible]);
  const onMouseLeave: React.MouseEventHandler = useCallback(() => setVisible(false), [setVisible]);

  return (
    <>
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          ref: setReferenceElement,
          onMouseEnter,
          onMouseLeave,
        })
      )}

      {(props.visible ?? visible) && (
        <Portal>
          <div ref={setPopperElement} style={popperStyles.popper} {...attributes.popper} className={styles.wrapper}>
            <div ref={setArrowElement} style={popperStyles.arrow} className={styles.arrow} />
            <div className={styles.tooltip}>{props.label}</div>
          </div>
        </Portal>
      )}
    </>
  );
};

Tooltip.defaultProps = {
  arrow: true,
  placement: "top",
};
