import cx from "classnames";
import React, { ButtonHTMLAttributes, forwardRef, useState } from "react";
import { Tooltip } from "shared/ui/tooltip";
import { LoadingDots } from "../loading-dots";
import styles from "./button.module.scss";

export type IButton = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type" | "prefix" | "suffix"> & {
  variant?: "ghost" | "unstyled";
  size?: "small" | "medium" | "large";
  type?: "primary" | "secondary" | "success" | "error";
  loading?: boolean;
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  svgOnly?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  icon?: React.ReactNode;
  disabledTooltip?: React.ReactNode;
};

export const Button = forwardRef<HTMLButtonElement, IButton>(
  (
    {
      type,
      htmlType,
      size,
      variant,
      className,
      loading: propsLoading,
      children,
      onClick: propsOnClick,
      svgOnly,
      prefix,
      suffix,
      icon,
      disabled,
      ...restProps
    },
    ref
  ) => {
    const [localLoading, setLocalLoading] = useState(false);

    const onClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
      if (localLoading || propsLoading || disabled) return;
      // e.preventDefault();
      try {
        setLocalLoading(true);
        await propsOnClick?.(e);
      } finally {
        setLocalLoading(false);
      }
    };

    const loading = propsLoading ?? localLoading;

    const btn = (
      <button
        className={cx(
          styles.base,
          variant !== "unstyled" && styles.button,
          size === "small" && styles.small,
          size === "medium" && styles.medium,
          size === "large" && styles.large,
          type === "secondary" && styles.secondary,
          type === "success" && styles.success,
          type === "error" && styles.error,
          variant === "ghost" && styles.ghost,
          svgOnly && styles.svg_only,
          loading && styles.loading,
          disabled && styles.disabled,
          className
        )}
        type={htmlType}
        ref={ref}
        onClick={onClick}
        {...restProps}
      >
        {loading && (
          <div className={styles.loader}>
            <LoadingDots size={{ small: 4, medium: 5, large: 6 }[size || "medium"]} />
          </div>
        )}
        {!loading && prefix}
        {icon}
        {children}
        {suffix}
      </button>
    );

    if (restProps.disabledTooltip && disabled) {
      return <Tooltip label={restProps.disabledTooltip}>{btn}</Tooltip>;
    }

    return btn;
  }
);

Button.defaultProps = {};
