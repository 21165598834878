import Router from "next/router";
import pages from "pages/_router";
import { useEffect } from "react";
import { getQueryParam } from "shared/lib";
import { useAuthed } from "./authed.model";

export const useGuardAuthedOnly = () => {
  const authed = useAuthed();

  useEffect(() => {
    if (!authed) {
      const currentHref = location.href.substring(location.origin.length);
      Router.replace(pages.login.href + "?next=" + encodeURIComponent(currentHref));
    }
  }, [authed]);
};

export const useGuardGuestOnly = () => {
  const authed = useAuthed();

  useEffect(() => {
    if (authed) {
      const next = getQueryParam("next") || pages.dashboard.href;

      Router.replace(next);
    }
  }, [authed]);
};
