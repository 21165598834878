import React, { CSSProperties, useMemo } from "react";
import cx from "classnames";
import styles from "./loading-dots.module.scss";

export interface ILoadingDots {
  children?: React.ReactNode;
  size?: number;
  className?: string;
  style?: CSSProperties;
  htmlId?: string;
}

export const LoadingDots = (props: ILoadingDots) => {
  const style = useMemo(
    () => ({
      ...props.style,
      "--loading-dots-size": props.size && `${props.size}px`,
    }),
    [props.style, props.size]
  );

  return (
    <div className={cx(styles.loading_dots, props.className)} style={style} id={props.htmlId}>
      {props.children && <div className={styles.spacer}>{props.children}</div>}
      <span />
      <span />
      <span />
    </div>
  );
};

LoadingDots.defaultProps = {};
